<template>
  <div>
    <div class="divHeaderS" style="font-weight: 600">
      <span v-if="!isShowDeviceDetail">设备日志</span>
      <span @click="backDevicePage()" v-if="isShowDeviceDetail"><span style="color:#8c939d;">设备日志 /  </span>日志详情</span>
    </div>
    <div style="height: 1px;background-color:  rgb(215,215,215)"></div>

    <!-- 搜索区域-->
    <div v-if="!isShowDeviceDetail">
      <el-input placeholder="请输入Mac" v-model="inputMac" class="input-with-select"
                style="height: 40px;width: 300px;float: right">
        <el-button slot="append" icon="el-icon-search" @click="selectDeviceByMac()"></el-button>
      </el-input>
      <!--    设备列表，当前产品的设备-->
      <div style="margin-top: 20px">
        <div>
          <el-table :data="deviceList" stripe height="600" style="width: 100%;line-height: 60px">
            <el-table-column prop="mac" label="设备MAC" min-width="23%"></el-table-column>
            <el-table-column prop="onlineTime" label="首次上线" min-width="23%"
                             :formatter='onlineTimeFormat'></el-table-column>
            <el-table-column prop="outlineTime" label="最后上线" min-width="23%"
                             :formatter='outlineTimeFormat'></el-table-column>
            <el-table-column prop="onlineCount" label="上线次数" min-width="11%"></el-table-column>
            <el-table-column prop="isOnline" label="状态" :formatter='isOnlineFormat' min-width="10%"></el-table-column>
            <el-table-column fixed="right" label="操作" min-width="10%">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="deviceLog(scope.row)">查看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <!--    设备详情日志-->
    <div v-if="isShowDeviceDetail">
      <div style="line-height: 50px; float: left;margin-top: 20px">Mac: <span style="padding-left: 20px"> {{
          deviceMac
        }}</span></div>
      <div>
        <el-table :data="deviceLogList" stripe height="600" style="width: 100%;line-height: 60px">
          <el-table-column prop="ipAddress" label="IP" min-width="6%"></el-table-column>
          <el-table-column prop="cTime" label="时间" min-width="9%"
                           :formatter='deviceLogFormat'></el-table-column>
          <el-table-column prop="onlineTime" label="时长" min-width="9%"></el-table-column>
          <el-table-column prop="msgContent" label="事件" min-width="16%"></el-table-column>
          <el-table-column prop="msgContent" label="注释" min-width="10%"
                           :formatter='devicMsgDesc'></el-table-column>
          <!--          <el-table-column prop="type" label="类型" min-width="14%"></el-table-column>-->
                    <el-table-column prop="rssi" label="rssi" min-width="9%"></el-table-column>
                    <el-table-column prop="idp" label="pic" min-width="20%"></el-table-column>
                    <el-table-column prop="idv" label="video" min-width="20%"></el-table-column>
<!--          <el-table-column prop="reason" label="原因" min-width="18%"></el-table-column>-->
        </el-table>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'devicelog',

  data() {
    return {
      isShowDeviceDetail: false,
      inputMac: "",
      proDetail: null, // 产品信息
      deviceList: [],
      deviceLogList: [],
      deviceMac: "",
    }
  },
  methods: {
    // 格式化显示时间
    deviceLogFormat(row) {
      return row.cTime.substring(0, 10) + " " + row.cTime.substring(11, 19)
    },
    devicMsgDesc(row) {
      let dp = row.msgContent;

      if (dp === "A530010334F5") {
        return "出行开"
      }
      if (dp === "A530010435F5") {
        return "出行关"
      }
      if (dp === "A530010132F5") {
        return "同意远程"
      }
      if (dp === "A530010031F5") {
        return "拒绝远程"
      }

      if (dp.length > 2) {
        let d3 = dp.substring(4, 6);
        let d5 = dp.substring(8, 10);

        let dr5 = d5;
        if (d3 === "01") { // 添加指纹,处理00空指纹
          if (d5 === "00") {
            return "添加指纹0"
          } else {
            return "添加指纹" + dr5
          }
        }
        if (d3 === "02") {  // d5删除指纹编号
          return "删除指纹" + dr5
        }
        if (d3 === "03") { // 低电量
          if (d5 === "00") { // 严重不足
            return "电量严重低"
          }
          if (d5 === "01") { // 不足
            return "电量低"
          }
        }
        if (d3 === "04") { // 震动报警
          return "震动报警"
        }
        if (d3 === "05" && d5 === "00") { // 恢复出厂
          return "恢复出厂"
        }
        // 交互相关，远程开门
        if (d3 === "07") {  // 远程请求
          return "远程请求"
        }
        if (d3 === "09") {
          // 开门 指纹密码人脸开门 十进制指纹开箱编号
          let d6 = dp.substring(10, 12);
          let value = d6;
          if (d5 === "01") { // 指纹编号开门
            if (d6 === "00") {   // 判断d5类型 00不处理编号
              return "指纹开0"
            } else {
              return "指纹开" + value
            }
          }
          if (d5 === "02") { // 密码编号开门
            if (d6 === "00") {   // 判断d5类型 00不处理编号
              return "密码开0"
            } else {
              return "密码开" + value
            }
          }
          if (d5 === "03") { // 人脸编号开门
            if (d6 === "00") {
              return "脸/掌开0"
            } else {
              return "脸/掌开" + value
            }
          }
          if (d5 === "04") { //掌静脉编号开门
            if (d6 === "00") {   // 判断d5类型 00不处理编号
              return "掌静脉开0"
            } else {
              return "掌静脉开" + value
            }
          }
          if (d5 === "05") { // nfc卡片编号开门
            if (d6 === "00") {   // 判断d5类型 00不处理编号
              return "NFC开0"
            } else {
              return "NFC开" + value
            }
          }
          // ------------------------------------
          if (d5 === "06") { // nfc卡片编号开门
            if (d6 === "00") {
              return "临时密码开"
            } else {
              return "异常模式"
            }
          }
          if (d5 === "07") { // nfc卡片编号开门
            if (d6 === "00") {   // 判断d5类型 00不处理编号
              return "应急钥匙开"
            } else {
              return "异常模式"
            }
          }
          if (d5 === "08") { // nfc卡片编号开门
            if (d6 === ("00")) {   // 判断d5类型 00不处理编号
              return "安全模式开"
            } else {
              return "异常模式"
            }
          }
        }
        if (d3 === ("0A")) {  // 远程请求结果
          if (d5 === ("00")) { // 拒绝开箱
            return "拒绝远程"
          }
          if (d5 === ("01")) { // 同意开箱
            return "同意远程"
          }
        }
        if (d3 === ("0B")) {
          if (d5 === ("00")) {
            return "出行关闭"
          }
          if (d5 === ("01")) {
            return "出行打开"
          }
        }
        if (d3 === ("A0")) {  // d5添加密码编号
          return "添加密码" + dr5
        }
        if (d3 === ("A1")) {  // d5删除密码编号
          return "删除密码" + dr5
        }
        if (d3 === ("A2")) {
          // 胁迫信息 240928跟新，胁迫推送带编号；非艾普卡麦设备不带编号
          if (d5 === ("01")) { //指纹胁迫编号开门
            return "胁迫指纹"
          }
          if (d5 === ("02")) { //密码胁迫编号开门
            return "胁迫密码"
          }
          if (d5 === ("03")) { //人脸胁迫编号开门
            return "胁迫人脸"
          }
          if (d5 === ("04")) { //掌静胁迫脉编号开门
            return "胁迫掌"
          }
          if (d5 === ("05")) { //掌静胁迫脉编号开门
            return "胁迫NFC"
          }
        }
        if (d3 === ("A3")) {
          if (d5 === ("01")) { // 密码错误
            return "密码告警"
          }
          if (d5 === ("02")) { // 指纹错误
            return "指纹告警"
          }
          if (d5 === ("03")) { // 人脸错误
            return "人脸告警"
          }
          if (d5 === ("04")) { // 未关门
            return "门长开"
          }
          if (d5 === ("05")) { // 出差模式开箱
            return "出行开"
          }
          if (d5 === ("06")) { // 出差模式开箱
            return "掌告警"
          }
          if (d5 === ("07")) { // 出差模式开箱
            return "NFC告警"
          }
          if (d5 === ("08")) {
            return "震动告警"
          }
        }
        if (d3 === ("A4")) { // 箱门已经关
          if (d5 === ("00")) {
            return "门已关"
          }
          if (d5 === ("FF")) {
            return "隐藏门状态"

          }
          if (d5 === ("FE")) {
            return "打开门状态"

          }

        }
        if (d3 === ("A5")) {
          return "电池" + d5
        }
        if (d3 === ("A6")) {
          if (d5 === ("00")) {
            return "关闭出行"
          }
          if (d5 === ("01")) {
            return "申请关出行"
          }
        }
        if (d3 === ("A8")) {  // 添加NFC卡
          if (d5 === ("00")) {
            return "添加NFC0"
          } else {
            return "添加NFC" + dr5
          }
        }
        if (d3 === ("A9")) {  // 删除NFC卡
          return "删除NFC" + dr5
        }
        if (d3 === ("11")) {  // d5添加人脸编号
          if (d5 === ("00")) {
            return "添加脸/掌0";
          } else {
            return "添加脸/掌" + dr5;
          }
        }
        if (d3 === ("12")) {  // d5删除人脸编号
          return "删除脸/掌" + dr5;
        }
        if (d3 === ("13")) {  // d5添加静脉编号
          if (d5 === ("00")) {
            return "添加脸/掌0";
          } else {
            return "添加脸/掌" + dr5;
          }
        }
        if (d3 === ("14")) {  // d5删除静脉编号
          return "删除脸/掌" + dr5;
        }
        if (d3 === ("0D") && d5 === ("00")) { // 空指纹开门
          return "指纹开"
        }
        if (d3 === ("C2")) {
          return "OTA"
        }
      } else {
        return "-"
      }
    },
    backDevicePage() {
      this.isShowDeviceDetail = false;
    },

    // 查看详情，同时实时刷新日志
    deviceLog(row) {
      this.isShowDeviceDetail = true;
      this.deviceMac = row.mac;
      this.$axios.get(this.url + "/deviceLog/deviceLog" + "?mac=" + row.mac).then(res => {
        console.log("deviceLog res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.deviceLogList = res.data.data;
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 查找设备
    selectDeviceByMac() {
      this.$axios.get(this.url + "/device/deviceMac" + "?mac=" + this.inputMac).then(res => {
        console.log("deviceMac res:", res);
        if (res.data.code !== 200) {
          this.$message.error(res.data.msg);
        } else {
          this.deviceList = res.data.data;
        }
      }).catch(err => {
        this.$notify({title: '错误', message: err, type: 'warning'})
        return false
      })
    },

    // 格式化表格
    onlineTimeFormat(row) {
      if (null == row.onlineTime) {
        return "/";
      }
      return row.onlineTime.substring(0, 10) + " " + row.onlineTime.substring(11, 19)
    },
    outlineTimeFormat(row) {
      return row.onlineTime.substring(0, 10) + " " + row.onlineTime.substring(11, 19)
    },
    isOnlineFormat(row) {
      if (row.isOnline === 0) {
        return "离线";
      } else {
        return "在线";
      }
    }

  },

  // 页面初始化执行
  mounted: function () {
    this.proDetail = JSON.parse(sessionStorage.getItem("product"));
    this.$axios.get(this.url + "/device/deviceList" + "?productKey=" + this.proDetail.productKey).then(res => {
      console.log("deviceLogList res:", res);
      if (res.data.code !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.deviceList = res.data.data;
      }
    }).catch(err => {
      this.$notify({title: '错误', message: err, type: 'warning'})
      return false
    })
  }
}

</script>

<style>

</style>